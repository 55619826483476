body,
html,
#root {
  height: 100%;
}

.app-layout {
  height: 100%;
}
.field-error {
  color: rgb(253, 41, 41);
  font-size: 0.8em;
}

/* stylelint-enable */
* {
  /* плашка-бегунок и фон */
  scrollbar-color: #999 #333;
}

*::-webkit-scrollbar {
  width: 10px; /* в основном для вертикальных полос прокрутки */
  height: 10px; /* в основном для горизонтальных полос прокрутки */
}

*::-webkit-scrollbar-thumb {
  /* плашка-бегунок */
  background-color: rgba(170, 170, 170, 0.75);
  border-radius: 4px;
  border: 1px solid #fff;
}

*::-webkit-scrollbar-track {
  /* фон */
  background: #ededed;
}
